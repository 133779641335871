import { LitElement, html } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../../../shared/components/form/Form';
import '../../../shared/components/form-feedback/FormFeedback';
import '../../../shared/components/form-error/FormError';
import '../../../shared/components/input/Input';
import '../../../shared/components/textarea/Textarea';
import '../../../shared/components/button/Button';
import '../../../shared/components/form-group/FormGroup';
import FormController from '../../../shared/controllers/FormController';
import schema from '../../../../../schemas/DocsFeedbackSchema';
import messages from '../../../../../schemas/DocsFeedbackSchema/messages';
import AppContextConsumer from '../../../shared/context/app-context/AppContextConsumer';
import DocsFeedbackApi from '../../../shared/api/DocsFeedbackApi';

class DocsFeedbackForm extends LitElement {
  static styles = styles;

  static properties = {
    helpful: {
      type: Boolean,
    },
    hidden: {
      type: Boolean,
    },
    currentUrl: {
      type: String,
    },
    popoverPositionUp: {
      type: Boolean,
    },
  };

  constructor() {
    super();
    updateWhenLocaleChanges(this);

    this.helpful = null;
    this.currentUrl = null;
    this.hidden = true;
    this.popoverPositionUp = false;
    this.messages = messages();
  }

  context = AppContextConsumer(this);

  form = new FormController(this, { onSubmit: (params) => this.onSubmit(params) });

  connectedCallback() {
    super.connectedCallback();

    this.DocsFeedbackApi = new DocsFeedbackApi({ env: this.context.value?.env });
    this.form.schema = schema(this.messages);

    this.currentUrl = window.location.href;
  }

  async onSubmit({ values }) {
    const payload = { ...values, ...this.context?.value?.tracking ?? {} };
    await this.DocsFeedbackApi.create(payload);

    this.dispatchEvent(new CustomEvent('tracking-ga', { composed: true, bubbles: true, detail: { category: 'docs-feedback', label: 'submit' } }));
    this.dispatchEvent(new CustomEvent('next-step', { composed: true, bubbles: true }));
  }

  render() {
    if (this.hidden) return html``;

    return html`
      <div class="feedback-popover-body ${this.popoverPositionUp ? 'above' : 'below'}">
        <p><small>${msg('Note that feedback is for documentation only and not Tenable products.')}</small></p>
        <tenable-form .errors=${this.form.errors} .values=${this.form.values} @form-submitted=${(e) => this.form.submitHandler(e)}>
          <tenable-form-error .errors=${this.form.formErrors}></tenable-form-error>

          <tenable-input type="hidden" name="currentUrl" value=${this.currentUrl} @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>
          <tenable-input type="hidden" name="helpful" value="${this.helpful === true ? 'yes' : 'no'}" @input-change=${(e) => this.form.inputHandler(e)}></tenable-input>

          <tenable-form-group>
            <tenable-input type="email" name="email" label="${msg('Email')}" ?invalid=${this.form.errors.email} @input-change=${(e) => this.form.inputHandler(e)} @input-blur=${(e) => this.form.validateField(e)}></tenable-input>
            <tenable-form-feedback message=${this.form.errors.email}></tenable-form-feedback>
          </tenable-form-group>

          <tenable-form-group>
            <tenable-textarea
              rows="5"
              name="comment"
              placeholder="${msg('Leave a comment...')}"
              label="${this.helpful ? msg('Comment (Optional)') : msg('Comment')}"
              ?invalid=${this.form.errors.comment}
              @input-change=${(e) => this.form.inputHandler(e)}
              @input-blur=${(e) => this.form.validateField(e)}></tenable-textarea>
            <tenable-form-feedback message=${this.form.errors.comment}></tenable-form-feedback>
          </tenable-form-group>

          <tenable-form-group>
            <tenable-button type="submit" color="primary" ?disabled=${this.form.isSubmitting}>
              ${this.form.isSubmitting ? msg('Sending...') : msg('Submit')}
            </tenable-button>
          </tenable-form-group>
        </tenable-form>
      </div>
    `;
  }
}

customElements.define('tenable-docs-feedback-form', DocsFeedbackForm);
