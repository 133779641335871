import { LitElement, html } from 'lit';
import { getDefaultEnv } from '../shared/utils/helpers';
import styles from '../shared/styles';
import '../shared/context/app-context/AppContextProvider';
import '../shared/components/localize/Localize';
import '../shared/components/tracking/Tracking';
import './components/docs-feedback-container/DocsFeedbackContainer';

class DocsFeedback extends LitElement {
  static styles = styles;

  static properties = {
    env: {
      type: String,
      reflect: true,
    },
  };

  constructor() {
    super();
    this.env = '';
  }

  connectedCallback() {
    super.connectedCallback();

    const { env } = getDefaultEnv(this.env, {
      production: ['docs.tenable.com'],
      staging: [
        'docs-staging.tenable.com',
        'docs-jp-staging.tenable.com',
        'docs-kr-staging.tenable.com',
        'docs-de-staging.tenable.com',
        'docs-fr-staging.tenable.com',
        'docs-zh-cn-staging.tenable.com',
        'docs-zh-tw-staging.tenable.com',
      ],
    });

    this.env = env;
  }

  render() {
    return html`
      <tenable-app-provider .value=${{ env: this.env }}>
        <tenable-tracking>
          <tenable-localize>
            <tenable-docs-feedback-container></tenable-docs-feedback-container>
          </tenable-localize>
        </tenable-tracking>
      </tenable-app-provider>
    `;
  }
}

customElements.define('tenable-docs-feedback', DocsFeedback);
