import { css } from 'lit';
import sharedStyles from '../../../shared/styles';

export default [
  sharedStyles,
  css`
    .feedback-popover-body {
      font-size: 14px;
      position: absolute;
      display: block;
      z-index: 1000;
      width: 16rem;
      border: 1px solid rgba(0,0,0,.1);
      border-radius: 4px;
      box-shadow: 0 5px 10px rgba(0,0,0,.05),0 2px 6px rgba(0,0,0,.025),0 1px 3px rgba(0,0,0,.025);
      margin-bottom: .5rem;
      margin-top: .5rem;
      padding: .7rem;
      background: white;
    }

    .feedback-popover-body.above {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    .feedback-popover-body.below{
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  `,
];
