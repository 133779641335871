import { msg } from '@lit/localize';

const messages = () => ({
  email: {
    matches: msg('Email not valid'),
  },
  comment: {
    max: msg('Exceeds max comment size'),
    required: msg('Comment is required'),
  },
  helpful: {
    required: msg('Page helpful is required'),
  },
  currentUrl: {
    required: msg('Page URL is required'),
  },
});

export default messages;
