import { css } from 'lit';
import sharedStyles from '../../../shared/styles';

export default [
  sharedStyles,
  css`
    .feedback-checkbox-container {
      display: inline-flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      margin-bottom: 0;
      margin-left: 14px;
    }

    .feedback-checkbox-container strong {
      font-weight: 400;
      color: #041e42;
      margin-left: 4px;
    }

    .feedback-checkbox-container input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }
  `,
];
