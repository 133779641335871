import { LitElement, html } from 'lit';
import { msg, updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../../../shared/components/icons/IconThumbsUp';
import '../../../shared/components/icons/IconThumbsDown';

class DocsFeedbackControls extends LitElement {
  static styles = styles;

  static properties = {
    helpful: {
      type: Boolean,
    },
  };

  constructor() {
    super();
    updateWhenLocaleChanges(this);
    this.helpful = null;
  }

  #toggle(event, value) {
    event.preventDefault();

    this.dispatchEvent(
      new CustomEvent('toggle-event', {
        bubbles: true,
        composed: true,
        detail: {
          srcEvent: event,
          value,
        },
      }),
    );
  }

  render() {
    return html`
      <div>
        ${msg('Did this page help you?')}

        <label class="feedback-checkbox-container" @click="${(e) => this.#toggle(e, { helpful: true })}">
          <input type="checkbox" name="feedback-checkbox" .checked=${this.helpful === true}>
          <tenable-icon-thumbs-up color=${this.helpful === true && '#3fae49'}></tenable-icon-thumbs-up>
          <strong>${msg('Yes')}</strong>
        </label>

        <label class="feedback-checkbox-container" @click="${(e) => this.#toggle(e, { helpful: false })}">
          <input type="checkbox" name="feedback-checkbox" .checked=${this.helpful === false}>
          <tenable-icon-thumbs-down color=${this.helpful === false && '#cb0123'}></tenable-icon-thumbs-down>
          <strong>${msg('No')}</strong>
        </label>
      </div>
    `;
  }
}

customElements.define('tenable-docs-feedback-controls', DocsFeedbackControls);
