const { object, string } = require('yup');

const schema = (messages = {}) => object().shape({
  email: string()
    .email(messages?.email?.matches ?? 'Email not valid'),
  comment: string()
    .max(1000, messages?.comment?.max ?? 'Exceeds max comment size')
    .when('helpful', {
      is: (helpful) => helpful === 'no',
      then: () => string()
        .required(messages?.comment?.required ?? 'Comment is required'),
    }),
  helpful: string()
    .required(messages?.helpful?.required ?? 'Page helpful is required')
    .oneOf(['yes', 'no']),
  currentUrl: string()
    .required(messages?.currentUrl?.required ?? 'Page URL is required'),
});

module.exports = schema;
