import { LitElement, html } from 'lit';
import { updateWhenLocaleChanges } from '@lit/localize';
import styles from './styles';
import '../docs-feedback-controls/DocsFeedbackControls';
import '../docs-feedback-form/DocsFeedbackForm';
import '../../../shared/components/step-wizard/StepWizard';
import '../docs-feedback-confirmation/DocsFeedbackConfirmation';

class DocsFeedbackContainer extends LitElement {
  static styles = styles;

  static properties = {
    popoverHidden: {
      type: Boolean,
      state: true,
    },
    helpful: {
      type: Boolean,
      state: true,
    },
    popoverPositionUp: {
      type: Boolean,
      state: true,
    },
    popoverHeight: {
      type: Number,
    },
  };

  constructor() {
    super();
    updateWhenLocaleChanges(this);

    this.popoverHidden = true;
    this.popoverHeight = 300;
    this.popoverPositionUp = true;
    this.helpful = null;
  }

  connectedCallback() {
    super.connectedCallback();

    this.#addEventListeners();
    this.#positionMenu();
  }

  #addEventListeners() {
    document.addEventListener('click', (event) => {
      const popoverContainer = this.renderRoot.querySelector('.feedback-popover-container');
      let contains = false;

      event.composedPath().forEach((item) => {
        if (item instanceof Element && popoverContainer.contains(item)) {
          contains = true;
        }
      });

      if (!contains) this.#closePopover();
    });
  }

  #closePopover() {
    this.popoverHidden = true;
    this.helpful = null;
  }

  #positionMenu() {
    const popoverContainer = this.shadowRoot.querySelector('.feedback-popover-container');

    if (!popoverContainer) return;

    const popoverRect = popoverContainer.getBoundingClientRect();
    const spaceAtTop = popoverRect.top;

    // If there is not enough space at the top of the popover container, position the popover down
    if (spaceAtTop < this.popoverHeight) {
      this.popoverPositionUp = false;
      return;
    }

    // Default to the popover being positioned up
    this.popoverPositionUp = true;
  }

  #toggleHandler(event) {
    const helpful = event?.detail?.value?.helpful ?? null;

    this.#positionMenu();

    // If the popover is open and the user clicks the same button, close the popover
    if ((this.popoverHidden === false) && this.helpful !== helpful) {
      this.helpful = helpful;
      return;
    }

    // If the popover is closed, open it
    if (this.popoverHidden) {
      this.helpful = helpful;
      this.popoverHidden = false;
      return;
    }

    // If the popover is open and the user clicks a different button, close the popover
    this.#closePopover();
  }

  render() {
    return html`
      <div class="docs-feedback-container">
        <div class="feedback-popover-container">
          <tenable-step-wizard>
            <div>
            <tenable-docs-feedback-form ?helpful=${this.helpful} ?hidden=${this.popoverHidden} ?popoverPositionUp=${this.popoverPositionUp}></tenable-docs-feedback-form>
            <tenable-docs-feedback-controls .helpful=${this.helpful} @toggle-event=${(e) => this.#toggleHandler(e)}></tenable-docs-feedback-controls>
            </div>
            <tenable-docs-feedback-confirmation></tenable-docs-feedback-confirmation>
          </tenable-step-wizard>
        </div>
      </div>
    `;
  }
}

customElements.define('tenable-docs-feedback-container', DocsFeedbackContainer);
