import { css } from 'lit';
import sharedStyles from '../../../shared/styles';

export default [
  sharedStyles,
  css`
    .docs-feedback-container {
      text-align: left;
      margin-top: 3rem;
      margin-bottom: 1rem;
      font-size: 16px;
      color: #415465;
      font-weight: 400;
    }

    .feedback-popover-container {
      display: inline-block;
      position: relative;
      padding: 8px 14px;
      border: 1px solid #dadfe1;
      border-radius: 4px;
    }
  `,
];
