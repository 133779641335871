import BaseApi from './BaseApi';

class DocsFeedbackApi extends BaseApi {
  async create(body) {
    const url = `${this.baseUrl}/evaluations/api/v1/docs/feedback`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    return this.parseResponse(response);
  }
}

export default DocsFeedbackApi;
